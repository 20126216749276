import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
	chips: {
		display: "flex",
		flexWrap: "wrap",
	},
	chip: {
		margin: 2,
	},
}));

const UniqueQueueSelect = ({ selectedQueueId, onChange, label = i18n.t("queueSelect.unique.inputLabel") }) => {
    const classes = useStyles();
    const [queues, setQueues] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                const { data } = await api.get("/queue");
                setQueues([{id : null, name:"...", color:"#fffff"},...data]);

            } catch (err) {
                toastError(err);
            }
        })();
    }, []);

    const handleChange = e => {
        onChange(e.target.value);
    };

    return (
        <div style={{ marginTop: 6 }}>
            <FormControl fullWidth margin="dense" variant="outlined">
                <InputLabel>{label}</InputLabel>
                <Select
                    labelWidth={310}
                    value={selectedQueueId}
                    onChange={handleChange}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                        },
                        getContentAnchorEl: null,
                    }}
                    renderValue={selected => {
                        const queue = queues.find(q => q.id === selected);
                        return queue ? (
                            <Chip
                                key={selected}
                                style={{ backgroundColor: queue.color }}
                                variant="outlined"
                                label={queue.name}
                                className={classes.chip}
                            />
                        ) : null;
                    }}
                >
                    {queues.map(queue => (
                        <MenuItem key={queue.id} value={queue.id}>
                            {queue.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default UniqueQueueSelect;